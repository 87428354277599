import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import * as styles from "../feature/separately/assets/css/separately.module.scss"
import separatelyImage from "../feature/separately/assets/images/separately.jpeg"

const SeparatelyPage = () => {
  return (
    <Layout>
      <h2>バラ売りクッキー</h2>
      <p>
        <img src={separatelyImage} alt=""></img>
      </p>
      <p>
        店主がその時に食べたかったり作りたかったりするクッキーが瓶に入って数種類並びます。
      </p>
      <p>毎週違ったお味のひとくちクッキーをおひとつからお選びいただけます◎</p>
      <p>
        どれでも<span className={styles.emphasis}>１個６０円</span>
        <br />
        入れ物ご持参で<span className={styles.emphasis}>１個５０円</span>
      </p>
    </Layout>
  )
}

export const Head = () => <Seo title="Separately" />

export default SeparatelyPage
